*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  width: 100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  min-height: 100vh;
}
