.header {
  height: 808px;
  padding: 24px 120px;

  border-radius: 0px 0px 72px 72px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 14.6%,
      rgba(0, 0, 0, 0) 152.4%
    ),
    linear-gradient(270deg, rgba(0, 0, 0, 0.7) 8.85%, rgba(0, 0, 0, 0) 102.57%),
    url("../images/header.jpeg"), lightgray 50% / cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mobile-menu {
      display: none;
    }
    .mobile-button {
      padding: 0;
      background-color: transparent;
      margin: 0;
      border: none;
      cursor: pointer;
      display: none;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__nav {
    ul {
      display: flex;
      align-items: center;
      gap: 16px;
      li {
        list-style: none;
        a {
          text-decoration: none;
          color: #fff;
          font-family: "Noto Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }

  .hero {
    margin-top: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    h1 {
      color: #fff;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 44.8px */
    }
    h2 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
    p {
      color: #fff;
      text-align: center;

      font-family: var(--secondary-font, "Noto Sans");
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-top: 16px;
      width: 60%;
      margin: 16px auto;
    }
    &__cta {
      width: 100%;
      .input-group {
        display: flex;
        justify-content: center;
        gap: 24px;
      }
      span {
        color: #fff;
        text-align: center;
        font-family: var(--secondary-font, "Noto Sans");
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        margin-top: 24px;
        display: block;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    padding: 24px 16px;
    height: 100vh;
    border-radius: 0px 0px 32px 32px;

    header {
      .mobile-button {
        display: block;
      }
    }
    &__cta {
      display: none;
    }
    &__nav {
      display: none;
    }
    .hero {
      margin-top: 120px;
      h1 {
        font-size: 32px;
      }
      h2 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
        width: 100%;
      }
      &__cta {
        .input-group {
          flex-direction: column;
          align-items: center;
          gap: 16px;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
