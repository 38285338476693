.contact {
  padding: 80px 120px;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.9) 44.43%,
      rgba(0, 0, 0, 0) 203.37%
    ),
    url("../images/header.jpeg"), lightgray 50% / cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  h1 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 56px */
    text-transform: capitalize;
  }
  p {
    color: var(--black-4, #f1f1f1);

    font-family: var(--secondary-font, "Noto Sans");
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    width: 50%;
    margin-top: 24px;
  }

  button {
    margin-top: 80px;
  }

  @media only screen and (max-width: $tablet) {
    padding: 80px 16px;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      width: 100%;
    }
    button {
      width: 100%;
      margin-top: 64px;
    }
  }
}
