.overview {
  padding: 144px 0px 16px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  h1 {
    color: var(--black-11, #2c2e2d);
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-transform: capitalize;
  }

  p {
    color: var(--black-9, #505151);
    text-align: center;

    font-family: var(--secondary-font, "Noto Sans");
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }

  .overview__stats {
    margin-top: 80px;
    display: flex;
    gap: 120px;
    align-items: flex-end;

    .stats__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      border-radius: 20px;
      width: 200px;
      padding-top: 24px;

      .stats__number {
        color: var(--black-11, #2c2e2d);
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 140%;
      }
      .stats__text {
        color: var(--black-10, #333534);
        text-align: center;

        font-family: var(--secondary-font, "Noto Sans");
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 25.2px */
      }

      &--60 {
        background: linear-gradient(
          180deg,
          #84cbb1 -15.62%,
          rgba(71, 177, 139, 0) 100%
        );
        height: 230px;
      }
      &--40 {
        background: linear-gradient(
          180deg,
          #acdccb -14.84%,
          rgba(244, 250, 248, 0) 100%
        );
        height: 187px;
      }
      &--30 {
        background: linear-gradient(
          180deg,
          #e3f3ee -14.22%,
          rgba(244, 250, 248, 0) 100%
        );
        height: 152px;
      }
    }
  }

  @media only screen and (max-width: $tablet) {
    padding: 64px 16px;

    h1 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }

    .overview__stats {
      gap: 16px;
      align-items: flex-end;

      .stats__item {
        width: 104px;
        .stats__number {
          font-size: 20px;
        }
        .stats__text {
          font-size: 14px;
        }
      }
    }
  }
}
