:root {
  --main-font: "Montserrat";
  --secondary-font: "Noto Sans";
  --black-3: #f5f5f5;
  --black-6: #c3c4c3;
  --black-8: #636464;
  --black-9: #505151;
  --black-10: #333534;
  --black-11: #2c2e2d;
  --black-13: #0f1110;
  --green-500: #47b18b;
  --green-3: #f8fcfa;
  --green-5: #e3f3ee;
  --green-4: #f4faf8;
  --accent-6: #fef4cf;
}

  $mobile: 425px;
  $tablet: 768px;
  $desktop: 1200px;
  $desktop-lg: 1440px;
  $desktop-xl: 1920px;
